import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { BASE_URL, priceunit } from "../../../ApiManager/client-config";
import {
  cartToggle,
  favouriteAdd,
  getItemDetail,
  menuGroupsList,
} from "../../../Redux/Actions";
import { searchState } from "../../../Redux/Reducers/searchReducer";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ProductModal from "../../ProductModal/ProductModal";
import _ from "lodash";
import LoaderSkeleton from "../../FeatureMenuProducts/skeleton";
import "./Mobilecategory.css";

interface ChildComponentProps {
  onClick: (item: any, event: React.MouseEvent) => void;
}

const MobileCategory = ({ onClick }: ChildComponentProps) => {
  const groupsData = useSelector((state: RootState) => state.login.groupsData);

  const category = useSelector(
    (state: RootState) => state.search.Category || 66
  );

  if (Array.isArray(groupsData) && groupsData.length > 0) {
    var selectedGroup = groupsData.find(
      (group: any) => group.group_id === category
    );
  }

  // const selectedGroup = groupsData.find(
  //   (group: any) => group.group_id === category
  // );

  console.log("here is selectedGroup", groupsData);

  const dispatch = useDispatch();

  //cart dispatch code
  const groupList = useSelector((state: RootState) => state.login.groupList);

  const cartToggle = useSelector((state: RootState) => state.login.cartToggle);
  const cart = useSelector((state: RootState) => state.login.cartData);
  const featuredProducts = useSelector(
    (state: RootState) => state.login.featuredProducts
  );
  const filteredItems = useSelector(
    (state: RootState) => state.login.filteredItems
  );
  const storeSelected = useSelector(
    (state: RootState) => state.login.storeSelected
  );
  const addressData = useSelector(
    (state: RootState) => state.login.addressData
  );
  const { t } = useTranslation();
  const [selectItem, setSeletItem] = useState({});
  const [open, setOpen] = useState(false);
  const timeOver = useSelector((state: RootState) => state.search.timerOver);

  const closeTime = useSelector((state: RootState) => state.search.CloseTime);
  useEffect(() => {
    let v = "";
    let doc = document as any;
    if (open) {
      doc.getElementsByTagName("html")[0]
        ? (doc.getElementsByTagName("html")[0].style.overflow = "hidden")
        : (v = "");
    } else {
      doc.getElementsByTagName("html")[0]
        ? (doc.getElementsByTagName("html")[0].style.overflow = "auto")
        : (v = "");
    }
  }, [open]);
  const onOpenModal = () => {
    dispatch(searchState(false));
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  const selectedItem = (item: any, event: any) => {
    onOpenModal();
    event.preventDefault();
    if (item.menu_item_id > 0) {
      dispatch(getItemDetail(item.menu_item_id));
    }
    setSeletItem(item);
  };

  const renderCartButton = (data: any) => {
    let cart: any = JSON.parse(data);
    let totalItemQuantity = 0;
    cart.map((item: any) => {
      totalItemQuantity += item.quantity;
    });
    return (
      <div className="cart-hover">
        <i className="fa fa-cart-plus"></i>
        <span className="badge-circle custom-default-green-button">
          {totalItemQuantity}
        </span>
      </div>
    );
  };

  const calcTotal = (data: any) => {
    let sum = 0;
    data.forEach((item: any) => {
      sum += parseInt(item.totalItemPrice);
    });
    return Math.round(sum);
  };
  let cartData: any = cart;
  const handleFavourite = (group: any, id: any, type: any, isFav: any) => {
    if (isFav) {
    } else {
      let token = sessionStorage.getItem("token");
      let data: any = {
        group_id: group,
      };
      if (type == "combo") {
        Object.assign(data, { combo_id: id });
      }

      if (type == "item") {
        Object.assign(data, { menu_item_id: id });
      }
      if (token) {
        favouriteAdd(data, storeSelected ? storeSelected.store_id : null);
      } else {
        toast.error("Sign in to add your favourite items", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <div className="container d-block d-md-none">
      <div
        className={`row ${
          closeTime || timeOver
            ? "margin_top_mobile_category"
            : "mobl_top_margin mb-3"
        }`}
      >
        {selectedGroup && selectedGroup.data.length == 0 ? (
          <LoaderSkeleton />
        ) : (
          selectedGroup &&
          selectedGroup.data.map((item: any, index: any) => (
            <div
              key={index}
              className="col-12 mb-3"
              style={{ marginBottom: "17px" }}
            >
              <div
                id="sister"
                className="hm-card-custom pb-4"
                onClick={(e) => selectedItem(item, e)}
              >
                <div className="row align-items-center">
                  <div className="col-8" onClick={(e) => selectedItem(item, e)}>
                    <h5 className="card-product-name">{item.item_name}</h5>
                    <p className="card-product-desc">
                      {item.item_size
                        ? JSON.parse(item.item_size)[0].description
                          ? JSON.parse(item.item_size)[0].description.length >
                            75
                            ? `${JSON.parse(
                                item.item_size
                              )[0].description.slice(0, 70)}....`
                            : JSON.parse(item.item_size)[0].description
                          : ``
                        : ""}
                    </p>
                    <span className="card-product-price pt-4">
                      {priceunit + JSON.parse(item.item_size)[0].mrp}
                    </span>
                  </div>
                  <div className="col-4">
                    <img
                      className="product-image"
                      style={{ borderRadius: 5 }}
                      src={`${BASE_URL}${
                        JSON.parse(item.item_size)[0].image_url
                      }`}
                      alt={`Product ${index}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <ProductModal
        open={open}
        onClose={onCloseModal}
        item={selectItem}
        checkSelcted={false}
        selectedModifier={undefined} isRow1ModifierSelected={false} isRow2ModifierSelected={false}      />
      {!_.isEmpty(cartData) && JSON.parse(cartData).length > 0 && (
        <div
          className="p_sticky_view_cart"
          onClick={() => {
            dispatch(cartToggle(false));
          }}
        >
          <div className="d-flex justify-content-between p_sticky_view_cart_btn">
            <div className="view_basket_icon"> {renderCartButton(cart)}</div>
            <span>View cart</span>
            <span>
              {"NOK"}
              {calcTotal(JSON.parse(cartData))}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileCategory;
