import { createStore, applyMiddleware, combineReducers, AnyAction } from "redux";
import loginReducer from "./Reducers/loginReducer";
import searchReducer from './Reducers/searchReducer'

const thunkMiddleware = require("redux-thunk").default;
const mainReducer = combineReducers({
  login: loginReducer,
  search:searchReducer,
});
const store = createStore(mainReducer, applyMiddleware(thunkMiddleware));
export default store;
export type RootState = ReturnType<typeof store.getState>;