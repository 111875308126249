import React, { useEffect } from "react";
import Payment from "../components/CheckOut/payment";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NewFooter from "../components/NewFooter";

function Checkoutnext(props: any) {
  useEffect(() => {
    document.title = "Checkout";
  });
  return (
    <div className="page-wrapper">
      <Header history={props.history} />
      <Payment history={props.history} getTimezone={undefined} />
      {/* <Footer /> */}
      <NewFooter />
    </div>
  );
}

export default Checkoutnext;
