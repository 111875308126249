import React from "react";
import "./NewFooter.css";
import { Link } from "react-router-dom";

const NewFooter = () => {

  const currentYear = new Date().getFullYear();

  return (
    <div className="main_footer pt-5">
      <div className="d-flex justify-content-center align-items-center flex-column pb-5">
        <div>
          <img
            // className="header-logo"
            src={process.env.PUBLIC_URL + "/logonew.png"}
            alt="Palace Cafe Logo"
            height={"30px"}
            width={"100px"}
          />
        </div>
        <div>
          <p className="footer_desc">
          Opplev autentisk Indisk mat av høy kvalitet. Våre retter er nøye utvalgt og strekker seg tilbake flere generasjoner i Sør-Asia.
          </p>
        </div>
      </div>
      <hr className="text-white w-100" />
      <div className="container pt-5">
        <div className="row">
          <div
            className="col-md-4 col-sm-6 col-12 d-flex flex-row  
                    justify-content-md-start justify-content-center mb-md-0 mb-2 "
          >
            <a href="/conditions" className="footer_link ">
              Terms & Conditions
            </a>
            <a href="/PrivacyPolicy" className="footer_link ">
              Privacy Policy
            </a>
            {/* <a href="/" className="footer_link ">
              Contact Us
            </a> */}
          </div>
          <div className="col-md-4 col-sm-6 col-12">
            <p className="footer_desc">
              ©  {currentYear} Khushi Restaurant. All Rights Reserved.
            </p>
          </div>
          <div className="col-md-4 col-sm-6 col-12 d-flex mt-md-0 mt-1 flex-row align-items-center justify-content-center">
            <p className="custom_text  px-0 mx-3 ">Powered By</p>
            <a href={"https://www.rizq.no"}>
              <img
                // className="header-logo"
                src={process.env.PUBLIC_URL + "/footer_side_logo.png"}
                alt="Palace Cafe Logo"
                height={"20px"}
                width={"70px"}
                style={{ objectFit: "contain", marginBottom: "12px" }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFooter;
