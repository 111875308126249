import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import NewFooter from '../components/NewFooter'
import LanguageBox from '../components/LanguageBox'



const Language = (props:any) => {
  return (
    <div className="page-wrapper">
      <Header history={props.history} />
    <LanguageBox/>
      <NewFooter />
</div>
  )
}

export default Language