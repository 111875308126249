import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SearchTerm {
  cartModal: boolean;
  isSearching: boolean;
  width: number;
  height: number;
  cartQuantity: number;
  SideBar: boolean;
  tableNumberCart: number;
  timerOver: boolean;
  storeOpenTime: any;
  storeCloseTime: any;
  SelectedStoreName: any;
  CloseTime: boolean;
  Category: any;
  OpenTimerModal: boolean;
  orderTime: any;
  isTime: any;
}

const isNorwayDayTime = () => {
  const now = new Date();
  const norwayTime = now.toLocaleString("en-US", { timeZone: "Europe/Oslo" });
  const hours = new Date(norwayTime).getHours();
  return hours >= 10 && hours < 18;
};

const initialState: SearchTerm = {
  width: 0,
  height: 0,
  isSearching: false,
  cartModal: false,
  cartQuantity: 0,
  SideBar: false,
  tableNumberCart: 0,
  timerOver: false,
  storeOpenTime: "",
  storeCloseTime: "",
  SelectedStoreName: "",
  CloseTime: false,
  Category: "",
  OpenTimerModal: false,
  orderTime: "",
  isTime: "",
};

const SearchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    searchState: (state, action: PayloadAction<boolean>) => {
      state.isSearching = action.payload;
    },
    widthState: (state, action: PayloadAction<number>) => {
      state.width = action.payload;
    },
    heightState: (state, action: PayloadAction<number>) => {
      state.height = action.payload;
    },
    cartOpenModal: (state, action: PayloadAction<boolean>) => {
      state.cartModal = action.payload;
    },
    isCartQuantity: (state, action: PayloadAction<number>) => {
      state.cartQuantity = action.payload;
    },
    isSideBar: (state, action: PayloadAction<boolean>) => {
      state.SideBar = action.payload;
    },
    setTableNumber: (state, action: PayloadAction<number>) => {
      state.tableNumberCart = action.payload;
    },
    setTimeOver: (state, action: PayloadAction<boolean>) => {
      state.timerOver = action.payload;
    },
    setTimeOrder: (state, action: PayloadAction<any>) => {
      state.isTime = action.payload;
    },
    setStoreOpenTime: (state, action: PayloadAction<any>) => {
      state.storeOpenTime = action.payload;
    },
    setStoreCloseTime: (state, action: PayloadAction<any>) => {
      state.storeCloseTime = action.payload;
    },

    setSelectedStoreName: (state, action: PayloadAction<any>) => {
      state.SelectedStoreName = action.payload;
    },
    setCloseTime: (state, action: PayloadAction<boolean>) => {
      state.CloseTime = action.payload;
    },
    setCategory: (state, action: PayloadAction<any>) => {
      state.Category = action.payload;
    },
    setOpenTimerModal: (state, action) => {
      state.OpenTimerModal = action.payload;
    },
    setOrderTime: (state, action) => {
      state.orderTime = action.payload;
    },
  },
});

export const {
  searchState,
  widthState,
  setSelectedStoreName,
  heightState,
  cartOpenModal,
  isCartQuantity,
  isSideBar,
  setTableNumber,
  setTimeOver,
  setTimeOrder,
  setStoreOpenTime,
  setStoreCloseTime,
  setCloseTime,
  setCategory,
  setOpenTimerModal,
  setOrderTime,
} = SearchSlice.actions;
export default SearchSlice.reducer;
