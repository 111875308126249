import React from "react";
import "./close.css";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";

const CloseHeader = () => {
  const openTime = useSelector(
    (state: RootState) => state.search.storeOpenTime
  );

  return (
    <div className="header-close d-flex justify-content-center align-items-center">
      <span>
        Khushi is currently closed you can place your orders after{" "}
        <span className="digits-fonts">{openTime}</span> . Thank you!
      </span>
    </div>
  );
};

export default CloseHeader;
