import React, { useEffect, useState } from "react";
import "./Nav.css";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  featuredProducts,
  getCart,
  searchItems,
  logoutCustomer,
  emptyFilteredItems,
  menuGroupsListHome,
  saveOrderType,
} from "../../../Redux/Actions/index";
import NavLinkNew from "./NavLink new";
import ModeToggler from "../../ModeToggler";
import { RootState } from "../../../Redux/store";

const navLinks1 = [
  { navLinkId: "Home", scrollToId: "homeContainer" },
  { navLinkId: "About", scrollToId: "aboutContainer" },
  { navLinkId: "Contact", scrollToId: "contactContainer" },
];
function Nav(props: any) {
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const [search, setSearch] = useState("");
  const timeOver = useSelector((state: RootState) => state.search.timerOver);
  const closeTime = useSelector((state: RootState) => state.search.CloseTime);
  useEffect(() => {
    setSearch("");
    props.emptyFilteredItems();
    dispatch(menuGroupsListHome(null));
  }, [dispatch]);

  useEffect(() => {
    if (props.groupsHome && props.groupsHome.length > 0) {
    }
  }, [props.groupsHome]);
  console.log("props.groupsHome", props.groupsHome);
  const sortedCategories = props.groupsHome.sort(
    (a: { priority: number }, b: { priority: number }) =>
      a.priority - b.priority
  );

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    if (e.target.value == "") {
      props.emptyFilteredItems();
    } else {
      props.searchItems({
        text: e.target.value,
        store_id: null,
        data: props.groupsData,
      });
    }
  };
  const clearSeach = () => {
    setSearch("");
    props.emptyFilteredItems();
  };
  const setModeOpenMenu = (mode: any) => {
    sessionStorage.setItem("orderType", mode);
    props.saveOrderType(mode);
    setOpenMenu(true);
  };
  const moveLeft = () => {
    let obj = document as any;
    obj.getElementById("moveCons").scrollLeft -= 200;
  };
  const moveRight = () => {
    let obj = document as any;
    obj.getElementById("moveCons").scrollLeft += 500;
  };
  const toggleSidebar = (condition: any) => {
    setOpenMenu(true);
  };

  const [activeSectionId, setActiveSectionId] = useState(null);

  const handleSetActiveSection = (sectionId: any) => {
    setActiveSectionId(sectionId);
    console.log("section id", sectionId);
  };

  const handleAutoScroll = () => {
    if (activeSectionId) {
      const parentElement = document.querySelector(
        ".outer-category"
      ) as HTMLElement;
      const activeElement = document.getElementById(activeSectionId);

      if (parentElement && activeElement) {
        const scrollLeftValue =
          activeElement.offsetLeft -
          parentElement.offsetWidth / 2 +
          activeElement.offsetWidth / 2;

        // Check if the scrollLeftValue is within bounds, adjust as needed
        const minScrollLeft = 0;
        const maxScrollLeft =
          parentElement.scrollWidth - parentElement.clientWidth;
        const clampedScrollLeft = Math.max(
          minScrollLeft,
          Math.min(maxScrollLeft, scrollLeftValue)
        );

        // Scroll without smooth behavior for testing
        parentElement.scrollLeft = clampedScrollLeft;
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      handleAutoScroll();
    };

    // Add an event listener to handle auto-scroll when the active section changes or on component mount
    window.addEventListener("resize", handleResize);
    handleAutoScroll(); // Initial auto-scroll on mount

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeSectionId]);

  return (
    <>
      <nav
        className="container fit-container"
        style={{
          height: "0px",
          maxHeight: "0px",
          padding: "0px 0px",
          top: "20px",
        }}
        id="main-container"
      >
        <div
          className={`${
            timeOver || closeTime
              ? "sticky-subheader-outer-over"
              : "sticky-subheader-outer"
          }`}
        >
          <div className="row">
            <nav className="col-12">
              {/* <section style={{"height":"80px","backgroundColor":"antiquewhite"}}> */}
              <div onClick={moveLeft} className="category-left-arrow left-next">
                {" "}
                <i
                  className="fa fa-angle-left"
                  aria-hidden="true"
                  style={{ color: "white" }}
                ></i>{" "}
              </div>
              <div
                className={`outer-category`}
                style={{ display: "flex" }}
                id="moveCons"
              >
                <div
                  className="custom-mobile font_custom"
                  style={{
                    display: "flex",
                  }}
                >
                  <nav className="nb-nav-cat" style={{ display: "contents" }}>
                    {props &&
                      sortedCategories.map((group: any, idx: any) => (
                        <NavLinkNew
                          key={idx}
                          // id={idx}
                          navLinkId={group.group_name}
                          scrollToId={group.group_id}
                          onSetActiveSection={handleSetActiveSection}
                        />
                      ))}
                  </nav>
                </div>
              </div>
              {/* </section>   */}

              <div
                onClick={moveRight}
                className="category-right-arrow category-right-next"
                id="moveCons"
              >
                {" "}
                <i
                  className="fa fa-angle-right"
                  aria-hidden="true"
                  style={{ backgroundColor: "none" }}
                ></i>{" "}
              </div>
            </nav>
          </div>
        </div>
      </nav>
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    groupsHome: state.login.groupsHome,
    groupList: state.login.groupList,
    //   cart: state.login.cartData,
    GroupName: state.login.GroupName,
    groupsData: state.login.groupsData,
    //   storeSelected: state.login.storeSelected,
    //   cartToggle: state.login.cartToggle,
    orderType: state.login.orderType,
    //   cityName: state.login.cityName,
    //   tradeZoneName: state.login.tradeZoneName,
    //   tradeAreaName: state.login.tradeAreaName,
    //   addressData: state.login.addressData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutCustomer: function () {
      dispatch(logoutCustomer());
    },
    // findLocalStores: function (lat: any, long: any) {
    //   dispatch(findLocalStores(lat, long));
    // },
    // menuCombosList: function (data: any) {
    //   dispatch(menuCombosList(data));
    // },
    getCart: function (history?: any) {
      dispatch(getCart(history));
    },
    saveOrderType: function (mode: any) {
      dispatch(saveOrderType(mode));
    },
    menuGroupsListHome: function (store_id: any) {
      dispatch(menuGroupsListHome(store_id));
    },

    featuredProducts: (store_id: any) => {
      dispatch(featuredProducts(store_id));
    },
    searchItems: (data: any) => {
      dispatch(searchItems(data));
    },
    emptyFilteredItems: () => {
      dispatch(emptyFilteredItems());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
