import React, { useEffect, useState } from "react";
import "./newSlider.css";
import { getItemDetail, menuHeroItems } from "../../../Redux";
import { useSelector, useDispatch } from "react-redux";
import { API_URL } from "../../../ApiManager/client-config";
import { setInterval } from "timers";
import ProductModal from "../../ProductModal/ProductModal";
import { event } from "jquery";
import BannerModal from "../../BannerModal/BannerModal";
import { ComboListProps } from "../../Interface/loginInterface";
import { RootState } from "../../../Redux/store";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const NewSliderv2 = () => {
  const dispatch = useDispatch();
  const [selectItem, setSeletItem] = useState({});
  const [open, setOpen] = useState(false);
  const [openBanner, setOpenBanner] = useState(false);
  let currentIndex = 0;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeDot, setActiveDot] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [intervalId, setIntervalId] = useState<number | null>(null);
  const timerOver = useSelector((state: RootState) => state.search.timerOver);
  const closeTime = useSelector((state: RootState) => state.search.CloseTime);
  const [timer, setTimer] = useState<any | null>(null);

  interface stateInterface {
    login: ComboListProps;
  }

  const heroItemState: any = useSelector(
    (state: stateInterface) => state.login.heroItem
  );

  useEffect(() => {
    dispatch(menuHeroItems());
  }, []);

  const nextSlide = () => {
    if (modalOpen) {
      return;
    }

    const arrayLength: any = localStorage.getItem("array");
    setCurrentSlide((prevSlide: number) => {
      return prevSlide === arrayLength - 1 ? 0 : prevSlide + 1;
    });
  };

  const prevSlide = () => {
    const arrayLength: any = localStorage.getItem("array");
    setCurrentSlide((prevSlide: number) =>
      prevSlide === 0 ? arrayLength - 1 : prevSlide - 1
    );
  };

  const updateCount = () => {
    if (!modalOpen && !timer) {
      setTimer(
        setInterval(() => {
          nextSlide();
        }, 10000)
      );
    }
  };

  useEffect(() => {
    updateCount();

    return () => clearInterval(timer);
  }, [timer, modalOpen]);

  const onCloseModal = () => {
    setOpen(false);
    setModalOpen(false);
  };

  const onOpenModal = () => {
    setOpen(true);
    setModalOpen(true);
    clearInterval(timer);
    console.log("");
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };

  useEffect(() => {
    console.log("modal open", modalOpen);
    // Additional actions after modalOpen is updated
  }, [modalOpen]);

  const onCloseBannerModal = () => {
    setOpenBanner(false);
  };

  const onOpenBannerModal = () => {
    setOpenBanner(true);
    clearInterval(timer);
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };
  const selectedItem = (item: any, event: any) => {
    onOpenModal();
    // event.preventDefault();
    if (item.menu_item_id > 0) {
      dispatch(getItemDetail(heroItemState[currentSlide].menu_item_id));
    }
    setSeletItem(heroItemState[currentSlide]);
  };

  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    const touchDiff = touchEndX - touchStartX;
    if (touchDiff > 50) {
      prevSlide();
    } else if (touchDiff < -50) {
      nextSlide();
    }
  };

  const [width, setWidth] = useState(window.innerWidth);
  const isSmallScreen = width < 600;

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isDeviceUnder600px = () => {
    return window.innerWidth < 600;
  };

  const isDeviceUnder380px = () => {
    return window.innerWidth < 600;
  };

  return (
    <div
      className={`  ${
        closeTime || (timerOver && isSmallScreen)
          ? "slider-container-b"
          : "slider-container"
      }`}
      style={{
        marginTop: `${timerOver || closeTime ? "17.2rem" : "12.5rem"}`,
        marginBottom: `${closeTime || timerOver ? "-110px" : "-48px"}`,
      }}
    >
      <div className="row banner-row">
        <div className="col-12 p-0">
          <div
            className="hero-slider"
            // style={{ background: "#2f4e49" }}
            id="heroSlider"
            onTouchStart={(e: any) =>
              isDeviceUnder600px() && handleTouchStart(e)
            }
            onTouchMove={(e: any) => isDeviceUnder600px() && handleTouchMove(e)}
            onTouchEnd={(_e: any) => isDeviceUnder600px() && handleTouchEnd()}
          >
            {heroItemState?.length > 0 &&
              heroItemState?.map((banner: any, index: any) => (
                <span key={index}>
                  <div
                    onClick={(e: any) => {
                      selectedItem(banner, e);
                    }}
                    style={{
                      background: `linear-gradient(97deg, rgb(0 0 0 / 38%) 30.56%, rgb(4 4 4 / 29%) 42.39%, rgb(169 163 163 / 0%) 64.46%), url(${API_URL}${banner.image})`,
                    }}
                    className={`slider-item ${
                      currentSlide === index ? "active" : ""
                    }`}
                  >
                    <div className="container">
                      <div className="row">
                        <div
                          className={`col-md-6 col-9 d-flex flex-column justify-content-center position-relative
                         align-items-start ${
                           banner.banner_title.length >= 22
                             ? "margin_small_device"
                             : "custom-content"
                         }`}
                        >
                          <h1
                            className={`${
                              banner.banner_title.length >= 22
                                ? "banner_title_custom"
                                : "banner_title"
                            } `}
                            style={{ paddingLeft: "40px" }}
                          >
                            {banner.banner_title}
                          </h1>
                          <p
                            className="banner_description "
                            style={{
                              paddingLeft: "40px",
                              paddingBottom: "80px",
                            }}
                          >
                            {banner.banner_description}
                          </p>
                          <button
                            className={`order_button ${
                              banner.banner_title.length >= 22 &&
                              "mt_cusotm_button"
                            }`}
                          >
                            Order Now
                          </button>
                        </div>
                        <div className="col-6 text-center">
                          <img
                            className="banner-image d-none"
                            style={{
                              margin: "auto",
                              width: "641px",
                              height: "360px",
                              objectFit: "cover",
                            }}
                            src={`${API_URL}${banner.image}`}
                          />
                        </div>
                        <div
                          className="custom-banner-mobile"
                          style={{ alignItems: "center" }}
                          onClick={(e: any) => selectedItem(banner, e)}
                        ></div>
                      </div>
                    </div>
                  </div>
                </span>
              ))}
          </div>
          <div>
            <div
              className="dots-container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                bottom: "10px",
                left: "42.5%",
                top: "90%",
              }}
            >
              {heroItemState?.map((_: any, index: any) => (
                <div
                  key={index}
                  className={`dot ${
                    currentSlide === index ? "active" : "unactive"
                  }`}
                />
              ))}
            </div>
            <div className="slider-navigation">
              <span
                className="slide-left"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: "33%",
                  left: "-3%",
                }}
                onClick={prevSlide}
              >
                <div style={{ width: "5%" }}>
                  <KeyboardArrowLeft
                    className="icon_class"
                    style={{ fontSize: "40px" }}
                  />
                </div>
              </span>
              <span
                className="slide-right"
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  justifyContent: "center",
                  top: "33%",
                  right: "-0.8%",
                }}
                onClick={nextSlide}
              >
                <KeyboardArrowRight
                  className="icon_class"
                  style={{ fontSize: "40px" }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <ProductModal
        open={open}
        onClose={onCloseModal}
        item={selectItem}
        checkSelcted={false}
        selectedModifier={undefined} isRow1ModifierSelected={false} isRow2ModifierSelected={false}      />
      <BannerModal openBanner={openBanner} onCloseBanner={onCloseBannerModal} />
    </div>
  );
};

export default NewSliderv2;
