
import React from 'react'

import UserProfile from '../components/Profile'
import Header from '../components/Header'
import Footer from '../components/Footer'
import NewFooter from '../components/NewFooter';

function Profile(props:any) {
  let history = props.history;

  return (
    <div className="page-wrapper">
      <Header history={history} />
         <UserProfile/>
      {/* <Footer/> */}
      <NewFooter />
    </div>
  )
}

export default Profile
