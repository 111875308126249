import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import './conditionx.css'
class Conditions extends Component {
  constructor(props: any) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    return (
      <div className="main-terms" style={{ backgroundColor: "#f7f7f7" }}>
        <div className="about-section">
          <div className="container">
            <h2 className="subtitle">Vilkår og betingelser</h2>
            <div className="row p-3">
              <div
                className="col-lg-12 col-12 p-5"
                style={{
                  margin: "auto",
                  backgroundColor: "white",
                  borderRadius: "24px",
                  boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.08)",
                }}
              >
                <div className="about-details">
                  <div className="col-lg-12">
                    {/* <p className="text-center"><strong>PROVISIONS FOR PRIVACY</strong></p> */}

                    <p>
                      <strong className="condition-heading">1. Firma</strong>
                    </p>
                    <p>
                      Khushi restaurant Nettbestilling{" "}
                      <a href="https://www.order.khushi.rizq.no//">
                      https://www.order.khushi.rizq.no/
                      </a>{" "}
                      tilhører Casso AS, organisasjonsnummer 923
                      139 583. Informasjon og bilder på nettstedet er eid av
                      selskapet eller partner og kan ikke kopieres uten
                      tillatelse.
                      <br />
                      Kontakt informasjon
                      <br />
                      Telefon nummer: +47 401 63 501
                      <br />
                      Epost adresse: cassoadm@gmail.com
                      <br />
                      Juridisk adresse: Seilduksgata 5C, 0553 Oslo
                    </p>

                    <p>
                      <strong>2. Priser</strong>
                    </p>
                    <p>
                      Alle priser vises i Norske kroner og er inkludert MVA. I
                      handlekurven ser du totalprisen inkludert alle avgifter.
                    </p>

                    <p>
                      <strong>3 Betaling</strong>
                    </p>
                    <p>Trekk av kort gjøres umiddelbart med kjøp</p>

                    <p>
                      <strong>4. Frakt</strong>
                    </p>
                    <p>Vi har ikke frakt</p>

                    <p>
                      <strong>4.1 Levering</strong>
                    </p>
                    <p> Vi kan ikke levering via nettbutikken vår</p>

                    <p>
                      <strong>4.2 Skadede varer og reklamasjonsrett</strong>
                    </p>
                    <p>
                      Når du mottar din ordre, er det viktig å sjekke varene for
                      skader eller feil. Skulle varen være skadet eller ødelagt,
                      kan du kontakte oss på cassoadm@gmail.com for videre
                      instruksjoner.
                    </p>

                    <p>
                      <strong>5. Angrerett</strong>
                    </p>
                    <p>
                      Når bestillingen er betalt fra din side, kan den ikke
                      endres eller avbestilles. Fersk mat er unntatt fra loven
                      om angrerett.
                    </p>
                    
                    <p>
                      <strong>
                        6. Personvern, sikkerhet og personopplysninger
                      </strong>
                    </p>
                    <p>
                      I forbindelse med bestillingen, godtar du av vi lagrer og
                      bruker informasjonen din. Vi gjør dette for å oppfylle
                      våre forpliktelser overfor deg. Vi dokumenterer også all
                      kommunikasjon vi har med deg via e-post, for å kunne tilby
                      tjenesten som du forventer av oss. Vi vil ikke dele dine
                      personlige informasjon til tredjepart.
                    </p>
                    <p>
                      <strong>7. Kontakt & Support</strong>
                    </p>
                    <p>
                      Ved spørsmål eller support, kontakte oss på
                      cassoadm@gmail.com
                    </p>
                    <p>
                      <strong>8. Cookies </strong>
                    </p>
                    <p>
                      En cookie er en tekstfil som sendes fra et nettsted til
                      datamaskinen der den er lagret enten i minnet (session
                      cookies) eller som en tekstfil (tekstbaserte
                      informasjonskapsler). Cookies brukes til å lagre logg-inn
                      eller handlekurven mens du surfer rundt på ulike
                      nettsteder. Hvis nettleseren din er satt til å ikke
                      akseptere cookies, vil du ikke være i stand til å gjøre
                      noen ordre på nettstedet vårt. Du kan enkelt endre dette i
                      innstillingene til nettleseren din. Vær oppmerksom på at
                      vi ikke bruker cookies for å lagre personlig informasjon
                      om deg
                    </p>
                    <p>
                      <strong>9.1 Force majeure</strong>
                    </p>
                    <p>
                      Særlige omstendigheter som ikke er under menneskelig
                      kontroll slik som krig, omfattende arbeidskonflikt,
                      blokade, brann, miljøkatastrofe, alvorlig infeksjon som
                      hindrer partene å oppfylle sine forpliktelser kan frigjøre
                      en part fra forpliktelsene. Slikt unntak gjelder under
                      forutsetning av at operasjonen ikke kan utføres under
                      disse betingelsene. Den andre parten skal straks varsles
                      om omstendighetene ved bruk av denne bestemmelsen
                    </p>
                    <p>
                      <strong>9.2 Endring av kontrakts</strong>
                    </p>
                    <p>
                      Etter at du som kunde har gjort et kjøp har vi har ikke
                      rett til å endre vilkårene i det aktuelle kjøpet med
                      mindre annet er avtalt
                    </p>
                    <p>
                      <strong>9.3 Klager</strong>
                    </p>
                    <p>
                      Ved klage, vennligst kontakt oss på
                      cassoadm@gmail.com. Din klage blir behandlet innen 30 dager
                    </p>
                    <p>
                      <strong>9.4 Tvister og lover</strong>
                    </p>
                    <p>
                      Norsk lov skal gjelde for alle kjøp gjort under disse
                      vilkårene. Tvister om kjøp under disse vilkår skal
                      utelukkende behandles av en Norsk domstol
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Conditions;
