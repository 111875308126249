import { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/store";
import Contact from "./screens/Contact";
import About from "./screens/Aboutus";
import Profile from "./screens/UserProfile";
import Login from "./screens/Login";
import Signup from "./screens/Signup";
import Location from "./screens/Checkout";
import Payment from "./screens/Payment";
import Home from "./screens/Home";
import NotFound from "./screens/404";
import Forgot from "./screens/Forget";
import Orders from "./screens/Orders";
import Faq from "./screens/Faq";
import Privacy from "./screens/Privacy";
import TrackOrder from "./components/TrackOrder";
import ConditionOfSale from "./screens/ConditionofSale";
import AcceptOrder from "./screens/Accept";
import CookieModal from "./components/cookie/CookieModal";
import Language from "./screens/Language";
import TrackingOrder from "./screens/TrackingOrder";
import Confirm from "./screens/Confirm";

const token = sessionStorage.getItem("token");

function App() {
  const [isOpen, setIsOpen] = useState(true);
  let location = useLocation();
  const toggleHtmlOverflow = (hidden: any) => {
    document.documentElement.style.overflow = hidden ? "hidden" : "auto";
  };
  useEffect(() => {
    toggleHtmlOverflow(isOpen);
  }, [isOpen]);

  useEffect(() => {}, [location]);
  return (
    <Provider store={store}>
      {/* {isOpen && <CookieModal setIsOpen={setIsOpen} isOpen={isOpen} />} */}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/order" component={Orders} />
        <Route path="/aboutus" component={About} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/reset-password" component={Forgot} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/checkout" component={Location} />
        <Route exact path="/payment" component={Payment} />
        <Route exact path="/trackorder" component={TrackOrder} />
        <Route exact path="/confirmorder" component={Confirm} />
        <Route exact path="/Faqs" component={Faq} />
        <Route exact path="/language" component={Language} />
        <Route exact path="/ordertrack" component={TrackingOrder} />
        <Route exact path="/PrivacyPolicy" component={Privacy} />
        <Route exact path="/conditions" component={ConditionOfSale} />
        <Route path="/accept" component={AcceptOrder} />
        <Route path="/contact" component={Contact} />
        <Route path="*" exact component={NotFound} />

        {/* <Route path="*" exact component={NotFound} /> */}
      </Switch>
    </Provider>
  );
}

export default App;
