import React, { useEffect } from "react";
import Header from "../components/Header/index";
import { Notfound } from "../components/NotFound";
import Footer from "../components/Footer";
import NewFooter from "../components/NewFooter";


function NotFound  (props:any) {

    useEffect(() => {
        document.title = "404";

        return () => {

        }
    }, [])


    return (
        <div>
            <div>
                <Header history={props.history} />
                <Notfound />
                {/* <Footer /> */}
                <NewFooter />
            </div>
        </div>
    );
    }

    export default NotFound