import React, { useEffect } from "react";
import Location from "../components/CheckOut/location";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NewFooter from "../components/NewFooter";
function Checkout(props: any) {
  useEffect(() => {
    document.title = "Checkout";
  });
  return (
    <div className="page-wrapper">
      <Header history={props.history} />
      <Location history={props.history} findLocalStoresWithoutMap={undefined} />
      {/* <Footer /> */}
      <NewFooter />
    </div>
  );
}

export default Checkout;
