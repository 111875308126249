import React, { useState } from 'react'
import './languagebox.css';



const LanguageBox = () => {

    const [activebtn, setActive] = useState('eng')

    const handleActive = (lang: any) => {
        setActive(lang)
    }

    return (
        <div className='container language-container d-flex justify-content-around flex-column'>
            <div className='d-flex flex-column'>
                <h4 className='lang-title'>Select Language</h4>
                <div className='btn_wrapper d-flex flex-column justify-content-center align-items-center'>
                    <button onClick={() => handleActive('eng')} className={`btn-inactive ${activebtn === 'eng' ? 'btn-active' : ''}`}>English</button>
                    <button onClick={() => handleActive('nor')} className={`btn-inactive ${activebtn === 'nor' ? 'btn-active' : ''}`}>Norwegian</button>
                </div>
            </div>
            <div>
                <button className={`update-btn`}>Update</button>
            </div>
        </div>
    )
}

export default LanguageBox