import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Login from "../components/Login";
import NewFooter from "../components/NewFooter";

function Newlogin(props: any) {
  return (
    <div className="page-wrapper">
      <Header history={props.history} />
      <Login />
     <NewFooter />
    </div>
  );
}

export default Newlogin;
