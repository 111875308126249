
import React from 'react'
import Signup from '../components/Signup'
import Header from '../components/Header'
import Footer from '../components/Footer'
import NewFooter from '../components/NewFooter';

function Newsignup(props:any) {
  let history = props.history;

  return (
    <div className="page-wrapper">
      <Header history={history} />
      <Signup />
      {/* <Footer /> */}
      <NewFooter />
    </div>
  )
}

export default Newsignup
