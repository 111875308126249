import React, { Component } from "react";
import Header from "../components/Header/index";
import Footer from "../components/Footer/index";
import Privacy from "../components/PrivacyPolicy/Privacy";
import NewFooter from "../components/NewFooter";
// import Sidebar from "../components/Header/mobileSidebar";

class PrivacyPolicy extends React.Component<{history:any},{}> {

  componentDidMount(){
    document.title = "About Us";
  }
  render() {
    let history = this.props.history;
    return (
      <div className="page-wrapper">
        {/* <Sidebar pageWrapId={"page-wrap"} outerContainerId={"root"} /> */}
        <div id="page-wrap">
          <Header history={history} />
          <Privacy />
          {/* <Footer /> */}
          <NewFooter />
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
