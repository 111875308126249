import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavContext } from '../context/NavContext';
import { setCategory } from '../../../Redux/Reducers/searchReducer';
import { useDispatch, useSelector } from 'react-redux';

const NavLinkNew = ({ navLinkId, scrollToId, onSetActiveSection }) => {
  const defaultNavId = 'Futo Maki - 6 biter'
  const { activeNavLinkId, setActiveNavLinkId } = useContext(NavContext);
  const linkRef = useRef();
  const [scrollDirection, setScrollDirection] = useState(null);
  const dispatch = useDispatch();

const handleClick = () => {
    document.getElementById(scrollToId).scrollIntoView({ behavior: 'smooth', block: 'center' });
    setActiveNavLinkId(navLinkId);
    dispatch(setCategory(scrollToId));
  };

  useEffect(() => {
    const isSmallDevice = window.innerWidth < 600;
    if (!activeNavLinkId && isSmallDevice) {
      setActiveNavLinkId('Milkshakes');
    }
  }, [activeNavLinkId, setActiveNavLinkId]);
  

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.8, // Adjust as needed
  };
  
  const intersectionCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        console.log(`Element with ID ${scrollToId} is intersecting.`);
        setActiveNavLinkId(navLinkId || 'Futo Maki - 6 biter');
      }
    });
  };
  
  useEffect(() => {
    const targetElement = document.getElementById(scrollToId);
  
    if (targetElement) {
      const observer = new IntersectionObserver(intersectionCallback, observerOptions);
      observer.observe(targetElement); // Observe here
  
      return () => {
        observer.disconnect();
      };
    } else {
      console.error(`Element with ID "${scrollToId}" not found. id`);
    }
  }, [scrollToId, navLinkId]);
  
  useEffect(() => {
    const initialScrollToId = 'Futo Maki - 6 biter';
    setActiveNavLinkId(initialScrollToId);
  }, [navLinkId]);

  



  return (
    <div className={`inner-single-category`} id='main-navigation-id'>
      <strong>
        <span
          ref={linkRef}
          id={navLinkId}
          className={activeNavLinkId === navLinkId ? 'activeClass' : ''}
          onClick={handleClick}
          style={{
            color: 'white',
            maxWidth: '100%',
            display: 'flex',
            fontSize: 'large',
            borderBottom: activeNavLinkId === navLinkId ? '2px solid white' : 'none',
          }}
        >
          <div className='font_custom' style={{ fontSize: '20px', fontWeight: '500', fontStyle: 'normal', letterSpacing: '-0.5px', lineHeight: '32px' }}>{navLinkId}</div>
        </span>
      </strong>
    </div>
  );
};

export default NavLinkNew;
