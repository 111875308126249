import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { updatePaymentStatus } from '../Redux/Actions/index'
import AcceptOrderScreen from '../components/AcceptOrder'
import NewFooter from '../components/NewFooter'
function AcceptOrder(props:any) {
    
  const dispatch = useDispatch()
	useEffect(()=> {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const order_id = urlParams.get("paymentid")
        // let order_id = sessionStorage.getItem('order_id');
        if(order_id)
        {
            dispatch(updatePaymentStatus(order_id))
            sessionStorage.removeItem('order_id')
        }
    }, [dispatch])
    return (
    <div className='page-wrapper'>
      <div id="page-wrap">
        <Header history={props.history} />
        <AcceptOrderScreen showDetail={undefined} />
        {/* <div>
            Order Confirmed
        </div> */}
          {/* <Footer /> */}
          <NewFooter />
      </div>
    </div>
  )
}

export default AcceptOrder

