import React from "react";
import Header from "../components/Header";
import NewFooter from "../components/NewFooter";
import OrderTrack from "../components/OrderTrack";

const TrackingOrder = (props: any) => {
  return (
    <div className="page-wrapper">
      <Header history={props.history} />
      <OrderTrack />
      <NewFooter />
    </div>
  );
};

export default TrackingOrder;
